module.exports = {
    siteTitle: "Holly Cummins", // <title>
    shortSiteTitle: "Holly Cummins", // <title> ending for posts and pages
    siteDescription:
        "Red Hatter, Java Champion, occasional maker and regular speaker.",
    siteUrl: "http://hollycummins.com",
    pathPrefix: "",
    siteImage: "/preview.png",
    siteLanguage: "en",

    /* author */
    authorName: "Holly Cummins",
    authorShortName: "Holly",
    authorTwitterAccount: "holly_cummins", // TODO remove this which is only used by Seo.js and is duplication

    /* info */
    headerTitle: "Holly Cummins",
    headerSubTitle: "",

    /* manifest.json */
    manifestName: "Holly Cummins",
    manifestShortName: "HollyBlog", // max 12 characters
    manifestStartUrl: "/index.html",
    manifestBackgroundColor: "white",
    manifestThemeColor: "#666",
    manifestDisplay: "standalone",

    // gravatar
    // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
    // Replace your email adress with md5-code.
    // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
    // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
    gravatarImgMd5: "",

    // social
    authorSocialLinks: [
        {
            name: "bluesky",
            url: "https://bsky.app/profile/hollycummins.com",
            display: "@holly-cummins.bsky.social"
        },
        {
            name: "mastodon",
            url: "https://hachyderm.io/@holly_cummins",
            display: "@holly_cummins@hachyderm.io"
        },
        {
            name: "linkedin",
            url: "https://www.linkedin.com/in/holly-k-cummins/",
            display: "Holly K Cummins"
        },
        {
            name: "github",
            url: "https://github.com/holly-cummins",
            display: "holly-cummins"
        },
        {
            name: "medium",
            url: "https://holly-k-cummins.medium.com",
            display: "Holly K Cummins"
        },
        {
            name: "twitter",
            url: "https://twitter.com/holly_cummins",
            display: "@holly_cummins"
        },
    ]
}
